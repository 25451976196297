@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
  --black: 0 0% 9%;
  --white: 0 0% 100%;

  --dark-50: 222 20% 96%;
  --dark-100: 220 14% 89%;
  --dark-200: 220 13% 77%;
  --dark-300: 216 12% 67%;
  --dark-400: 218 11% 55%;
  --dark-500: 220 9% 44%;
  --dark-600: 215 14% 33%;
  --dark-700: 217 19% 26%;
  --dark-800: 215 28% 19%;
  --dark-900: 221 39% 14%;

  --brand-blue: 230 100% 54%;

  --blue-25: var(--brand-blue) / 0.025;
  --blue-50: var(--brand-blue) / 0.05;
  --blue-100: var(--brand-blue) / 0.1;
  --blue-200: var(--brand-blue) / 0.2;
  --blue-300: var(--brand-blue) / 0.3;
  --blue-400: var(--brand-blue) / 0.4;
  --blue-500: var(--brand-blue) / 0.5;
  --blue-600: var(--brand-blue) / 0.6;
  --blue-700: var(--brand-blue) / 0.7;
  --blue-800: var(--brand-blue) / 0.8;
  --blue-900: var(--brand-blue) / 0.9;

  /* --gray-25: var(--black) / 0.025;
  --gray-50: var(--black) / 0.05;
  --gray-100: var(--black) / 0.1;
  --gray-200: var(--black) / 0.2;
  --gray-300: var(--black) / 0.3;
  --gray-400: var(--black) / 0.4;
  --gray-500: var(--black) / 0.5;
  --gray-600: var(--black) / 0.6;
  --gray-700: var(--black) / 0.7;
  --gray-800: var(--black) / 0.8;
  --gray-900: var(--black) / 0.9; */

  --gray-25: 0 0% 98%;
  --gray-50: 0 0% 95%;
  --gray-100: 0 0% 91%;
  --gray-200: 0 0% 82%;
  --gray-300: 0 0% 73%;
  --gray-400: 0 0% 64%;
  --gray-500: 0 0% 55%;
  --gray-600: 0 0% 45%;
  --gray-700: 0 0% 36%;
  --gray-800: 0 0% 27%;
  --gray-900: 0 0% 18%;

  --pastel-lilac-light: 232 100% 94%;
  --pastel-purple-light: 279 100% 94%;
  --pastel-pink-light: 319 100% 94%;
  --pastel-red-light: 0 100% 94%;
  --pastel-orange-light: 23 100% 92%;
  --pastel-brown-light: 29 49% 90%;
  --pastel-yellow-light: 43 100% 91%;
  --pastel-lime-light: 60 57% 88%;
  --pastel-sage-light: 89 69% 90%;
  --pastel-green-light: 128 73% 90%;
  --pastel-emerald-light: 162 67% 88%;
  --pastel-teal-light: 186 73% 88%;
  --pastel-blue-light: 208 100% 92%;
  --pastel-neutral-light: 0 0% 93%;

  --pastel-lilac-dark: 232 100% 82%;
  --pastel-purple-dark: 278 100% 81%;
  --pastel-pink-dark: 319 100% 80%;
  --pastel-red-dark: 0 100% 80%;
  --pastel-orange-dark: 23 100% 75%;
  --pastel-brown-dark: 28 49% 82%;
  --pastel-yellow-dark: 42 100% 75%;
  --pastel-lime-dark: 60 57% 75%;
  --pastel-sage-dark: 90 69% 80%;
  --pastel-green-dark: 128 71% 80%;
  --pastel-emerald-dark: 162 68% 77%;
  --pastel-teal-dark: 185 73% 78%;
  --pastel-blue-dark: 208 100% 81%;
  --pastel-neutral-dark: 0 0% 83%;

  --error-light: 0 100% 43%;
  --error-dark: 0 100% 34%;

  --warning-light: 25 89% 52%;
  --warning-dark: 25 83% 47%;

  --yield-light: 46 92% 53%;
  --yield-dark: 46 80% 48%;

  --success-light: 142 100% 34%;
  --success-dark: 141 100% 27%;

  --primary-bg: var(--brand-blue);
  --primary-fg: var(--gray-25);

  --radius: 0.5rem;
}

:root,
.light {
  --paper: var(--white);
  --paper-alt: var(--gray-25);
  --ink: var(--black);

  --ring: var(--gray-300);
  --border-light: var(--gray-50);
  --border: var(--gray-100);
  --border-selected: var(--gray-300);

  --accent-bg: var(--gray-50);
  --accent-fg: var(--gray-500);

  --muted-bg: var(--gray-100);
  --muted-fg: var(--gray-700);

  --secondary-bg: var(--gray-200);
  --secondary-fg: var(--brand-blue);

  --placeholder-fg: var(--gray-600);

  --skeleton-bg: var(--gray-50);

  --input-bg: var(--paper);
  --input-fg: var(--ink);

  --popover-bg: var(--paper);
  --popover-fg: var(--ink);

  --error-fg: var(--paper);
  --warning-fg: var(--paper);
  --yield-fg: var(--paper);
  --success-fg: var(--paper);

  --tw-shadow-color: var(--blue-300);
}

.dark {
  --paper: var(--black);
  --paper-alt: var(--gray-900);
  --ink: var(--white);

  --ring: var(--dark-700);
  --border: var(--dark-800);
  --border-light: var(--dark-700);
  --border-selected: var(--brand-blue);

  --muted-bg: var(--paper);
  --muted-fg: var(--dark-300);

  --secondary-bg: var(--blue-50);
  --secondary-fg: var(--brand-blue);

  --placeholder-fg: var(--dark-400);

  --skeleton-bg: var(--dark-800);

  --input-bg: var(--paper);
  --input-fg: var(--ink);

  --accent-bg: var(--dark-900);
  --accent-fg: var(--dark-400);

  --popover-bg: var(--paper);
  --popover-fg: var(--ink);

  --error-fg: var(--ink);
  --warning-fg: var(--ink);
  --yield-fg: var(--ink);
  --success-fg: var(--ink);

  --pastel-lilac-light: 232 100% 94%;
  --pastel-purple-light: 279 100% 94%;
  --pastel-pink-light: 319 100% 94%;
  --pastel-red-light: 0 100% 94%;
  --pastel-orange-light: 23 100% 92%;
  --pastel-brown-light: 29 49% 90%;
  --pastel-yellow-light: 43 100% 91%;
  --pastel-lime-light: 60 57% 88%;
  --pastel-sage-light: 89 69% 90%;
  --pastel-green-light: 128 73% 90%;
  --pastel-emerald-light: 162 67% 88%;
  --pastel-teal-light: 186 73% 88%;
  --pastel-blue-light: 208 100% 92%;
  --pastel-neutral-light: 0 0% 93%;

  --pastel-lilac-dark: 232 100% 82%;
  --pastel-purple-dark: 278 100% 81%;
  --pastel-pink-dark: 319 100% 80%;
  --pastel-red-dark: 0 100% 80%;
  --pastel-orange-dark: 23 100% 75%;
  --pastel-brown-dark: 28 49% 82%;
  --pastel-yellow-dark: 42 100% 75%;
  --pastel-lime-dark: 60 57% 75%;
  --pastel-sage-dark: 90 69% 80%;
  --pastel-green-dark: 128 71% 80%;
  --pastel-emerald-dark: 162 68% 77%;
  --pastel-teal-dark: 185 73% 78%;
  --pastel-blue-dark: 208 100% 81%;
  --pastel-neutral-dark: 0 0% 83%;

  --tw-shadow-color: var(--blue-600);
}

.blue {
  --paper: var(--primary-bg);
  --paper-alt: var(--blue-900);
  --ink: var(--white);

  --ring: var(--blue-500);
  --border: var(--blue-400);
  --border-light: var(--blue-500);
  --border-selected: var(--brand-blue);

  --muted-bg: var(--blue-400);
  --muted-fg: var(--blue-300);

  --secondary-bg: var(--blue-50);
  --secondary-fg: var(--brand-blue);

  --placeholder-fg: var(--blue-400);

  --skeleton-bg: var(--blue-400);

  --input-bg: var(--paper);
  --input-fg: var(--ink);

  --accent-bg: var(--blue-100);
  --accent-fg: var(--primary-bg);

  --popover-bg: var(--paper);
  --popover-fg: var(--ink);

  --error-fg: var(--ink);
  --warning-fg: var(--ink);
  --yield-fg: var(--ink);
  --success-fg: var(--ink);

  --pastel-lilac-light: 232 100% 94%;
  --pastel-purple-light: 279 100% 94%;
  --pastel-pink-light: 319 100% 94%;
  --pastel-red-light: 0 100% 94%;
  --pastel-orange-light: 23 100% 92%;
  --pastel-brown-light: 29 49% 90%;
  --pastel-yellow-light: 43 100% 91%;
  --pastel-lime-light: 60 57% 88%;
  --pastel-sage-light: 89 69% 90%;
  --pastel-green-light: 128 73% 90%;
  --pastel-emerald-light: 162 67% 88%;
  --pastel-teal-light: 186 73% 88%;
  --pastel-blue-light: 208 100% 92%;
  --pastel-neutral-light: 0 0% 93%;

  --pastel-lilac-dark: 232 100% 82%;
  --pastel-purple-dark: 278 100% 81%;
  --pastel-pink-dark: 319 100% 80%;
  --pastel-red-dark: 0 100% 80%;
  --pastel-orange-dark: 23 100% 75%;
  --pastel-brown-dark: 28 49% 82%;
  --pastel-yellow-dark: 42 100% 75%;
  --pastel-lime-dark: 60 57% 75%;
  --pastel-sage-dark: 90 69% 80%;
  --pastel-green-dark: 128 71% 80%;
  --pastel-emerald-dark: 162 68% 77%;
  --pastel-teal-dark: 185 73% 78%;
  --pastel-blue-dark: 208 100% 81%;
  --pastel-neutral-dark: 0 0% 83%;

  --tw-shadow-color: var(--blue-600);
}

@layer base {
  body {
    @apply bg-paper text-ink subpixel-antialiased;
    text-rendering: geometricPrecision;
  }

  a {
    @apply text-ink hover:decoration-ink underline decoration-gray-300 underline-offset-2 hover:cursor-pointer;
  }

  h1,
  h2,
  h3 {
    @apply font-sans font-normal leading-none tracking-normal;
  }

  h1 {
    @apply text-5xl font-bold;
  }

  h2 {
    @apply text-4xl font-bold;
  }

  h3 {
    @apply text-3xl;
  }

  ::selection {
    background-color: hsl(var(--blue-200));
    color: hsl(var(--ink));
  }

  :root {
    --sidebar-background: 0 0% 98%;
    --sidebar-foreground: 240 5.3% 26.1%;
    --sidebar-primary: 240 5.9% 10%;
    --sidebar-primary-foreground: 0 0% 98%;
    --sidebar-accent: 240 4.8% 95.9%;
    --sidebar-accent-foreground: 240 5.9% 10%;
    --sidebar-border: 220 13% 91%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }

  .dark {
    --sidebar-background: 240 5.9% 10%;
    --sidebar-foreground: 240 4.8% 95.9%;
    --sidebar-primary: 224.3 76.3% 48%;
    --sidebar-primary-foreground: 0 0% 100%;
    --sidebar-accent: 240 3.7% 15.9%;
    --sidebar-accent-foreground: 240 4.8% 95.9%;
    --sidebar-border: 240 3.7% 15.9%;
    --sidebar-ring: 217.2 91.2% 59.8%;
  }
}

@keyframes radial-animation {
  0% {
    background-position: 0% center;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% center;
  }
}

@layer utilities {
  .background-magic {
    @apply bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-purple-500 to-blue-800;
  }

  .background-magic-animated {
    animation: radial-animation 2s ease infinite;
    background-size: 200% 200%;
  }

  .text-magic {
    @apply bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-purple-500 to-blue-800 bg-clip-text text-transparent;
  }

  .text-magic-animated {
    animation: radial-animation 2s ease infinite;
    background-size: 200% 200%;
  }

  .text-magic-light {
    @apply bg-[radial-gradient(ellipse_at_center,_var(--tw-gradient-stops))] from-purple-200 via-purple-400 to-blue-500 bg-clip-text text-transparent;
  }

  .text-magic-light-animated {
    animation: radial-animation 2s ease infinite;
    background-size: 200% 200%;
  }

  .border-magic,
  .border-magic-disabled {
    @apply shadow-uniform-sm relative shadow-purple-500/5 transition-all;

    :focus-within {
      @apply shadow-uniform-lg shadow-purple-600/15 transition-all;
    }
  }

  .border-magic::before {
    content: "";
    animation: radial-animation 8s ease infinite;
    background-size: 200% 200%;
    @apply shadow-uniform-lg absolute -inset-[1px] rounded-md bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-purple-50 via-purple-500 to-blue-900 shadow-purple-500/20 transition-all;
  }

  .border-magic-disabled::before {
    content: "";
    animation: radial-animation 8s ease infinite;
    background-size: 200% 200%;
    @apply shadow-uniform-lg absolute -inset-[1px] rounded-md bg-[radial-gradient(ellipse_at_top,_var(--tw-gradient-stops))] from-purple-50/10 via-purple-500/10 to-blue-900/10 shadow-purple-500/25 transition-all;
  }
}
